export const fr = {
  /** Localized routes example */
  /*'routes.home': '/', */

  /** Localized page content */

  "app.name": "LGM Visites",
  "app.logo": "Logo LGM VISITES PWA V2.png",

  "home": "Accueil",
  "home.meta.title": "La Grande Motte",
  "home.meta.description":
    "La Grande Motte : Visitez la grande motte grâce à nos audioguide",
  "home.title": "Les Étapes",
  "home.description":
    "Bienvenue sur « LGM Visites », l’outil pratique qui vous dévoile La Grande Motte. Choisissez votre thématique pour une découverte sur mesure de la ville !",
  "home.visit_length": "Temps de parcours",
  "home.image.credit": "Olivier Maynard",
  "home.image.title": "Détail de la façade de la résidence Le Babylone",

  "menu": "Menu",
  "menu.home": "Accueil",
  "menu.share": "Partager",
  "menu.language.fr": "Version Française",
  "menu.language.en": "Version Anglaise",
  "menu.language.switch": "Changer de langue",
  "menu.credits": "Crédits",

  "poi.transcription": "Retranscription texte",
  "poi.more": "En savoir plus",

  "share.title": "Partagez la Grande Motte sur",
  "share.facebook":
    "https://www.facebook.com/sharer/sharer.php?u=https://www.lgmvisites.com/",
  "share.twitter":
    "https://twitter.com/intent/tweet?url=https://www.lgmvisites.com/&text=Venez découvrir La Grande Motte",
  "share.email":
    "mailto:?subject=Décrouvrez La Grande Motte&body=https://www.lgmvisites.com/",

  "credits": "Crédits",
  "credits.meta.title": "Crédits",
  "credits.meta.description":
    "Crédits de l'application de visite de La Grande Motte",
  "credits.title": "Crédits",
  "credits.content":
    "<p><a href='https://www.lagrandemotte.com/' target='_blank' title='Office de Tourisme de La Grande Motte'>©Office de Tourisme de La Grande Motte</a></p><p>Production et contenu éditorial : <a href='https://www.temaprod.fr/' target='_blank' title='Temaprod'>©Temaprod</a>, architecture et patrimoine</p><p>Voix off : Pierrick Fourcade et Anne de Boysson</p><p>Maquette et développement : <a href='https://www.cheztoi.eu/' target='_blank' title='Chez Toi'>©Chez Toi</a></p>",

  "error.meta.title": "Erreur 404",
  "error.meta.description":
    "Cette page a décidé de prendre des vacances. Réessayez plus tard.",
  "error.title": "Erreur 404",
  "error.content":
    "Cette page a décidé de prendre des vacances. Réessayez plus tard.",

  "consent.msg":
    "Cette application utilise des cookies à des fins statistiques pour analyser son utilisation et l'améliorer.",
  "consent.ok": "J'accepte",
  "consent.no": "Je refuse",

  "location.unavailable_msg": "⚠️ Géolocalisation non disponible. Vous pouvez l'activer en modifiant vos paramètres de sécurité / confidentialité de votre appareil.",
};
