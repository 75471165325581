export var data = {
  type: "FeatureCollection",
  name: "La ville jardin",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "Ligne 6", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.1008895, 43.565951],
          [4.09726, 43.5644463],
          [4.0938441, 43.5630901],
          [4.0922977, 43.562559],
          [4.0927197, 43.5614412],
          [4.0945008, 43.5555569],
        ],
      },
    },
  ],
};
