import React from "react";
import { LocalizedLink } from "../../modules/i18n";
import { AppRoute } from "../../const";
import { generatePath } from "react-router";

export function Title(props) {
  const tour = props.tour;
  const locale = props.locale;
  const hasSubtitle = tour.subtitle[locale] !== "" ? true : false;

  return (
    <div id="tour-title" className={`title bg-${tour.color}`}>
      <LocalizedLink
        className={`bg-${tour.color}`}
        id="back"
        to={generatePath(AppRoute.Home)}
      ></LocalizedLink>
      <div className="number tour-number">
        <span>{tour.number}</span>
      </div>
      <div className="h1 tour-h1">
        <h1>{tour.name[locale]}</h1>
        <p className={hasSubtitle ? "subtitle" : "hidden"}>
          {tour.subtitle[locale]}
        </p>
      </div>
    </div>
  );
}
