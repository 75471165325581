import React from "react";
import { Routes } from "react-router";
import { useIntl } from "react-intl";
import { AppDefaultLanguage } from "../../../const";

export function LocalizedRoutes(props)  {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { locale } = useIntl();

  const lang = props.defaultLanguage || AppDefaultLanguage;

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  const localizeRoutePath = (path) => {
    switch (typeof path) {
      case "undefined":
        return undefined;
      case "object":
        return path.map((key) => {
          return locale === lang ? key : `/${locale}` + key;
          // return locale === lang ? formatMessage({ id: key }) : `/${locale}` + formatMessage({ id: key });
        });
      default:
        const isFallbackRoute = path === "*";
        return isFallbackRoute
          ? path
          : locale === lang
          ? path
          : `/${locale}` + path;
      // locale === lang ? formatMessage({ id: path }) : `/${locale}` + formatMessage({ id: path });
    }
  };

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Routes location={props.location}>
      {props.children.map((child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              key: child.props.path,
              path: localizeRoutePath(child.props.path),
            })
          : child;
      })}
    </Routes>
  );
};
