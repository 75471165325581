import React, { useState } from "react";

export function UserMarker(props) {
  
  const [internalHover] = useState(props.hover || false);
  const hover =
    typeof props.hover === "undefined" ? internalHover : props.hover;

  return (
    <div
      style={{
        position: "absolute",
        transform: `translate(${props.left}px, ${props.top}px)`,
        filter: hover ? "drop-shadow(0 0 4px rgba(0, 0, 0, .3))" : "",
        pointerEvents: "none",
        cursor: "pointer",
        ...(props.style || {}),
      }}
      className={props.className ? `${props.className} pigeon-click-block` : "pigeon-click-block"}
    >
      <div id="marker-current-location">
        <div className="current-location-pulsate"></div><div className="current-location"></div>
      </div>
    </div>
  );
}
