import React from "react";
import { Helmet } from "react-helmet-async";
import Category from "../Category/Category";
import {Figure} from "./Figure";

function Home(props) {
  const locale = props.locale;
  const formatMessage = props.formatMessage;
  const HomeImg = "Accueil.jpg";

  const legend = formatMessage({ id: "home.image.title" });
  const credit = formatMessage({ id: "home.image.credit" });

  return (
    <div id="home" className="scroll-wrapper">
      <Helmet>
        <title>{`${formatMessage({ id: "home.meta.title" })} | ${formatMessage({ id: "app.name" })}`}</title>
        <meta
          name="description"
          content={formatMessage({ id: "home.meta.description" })}
        />
      </Helmet>
      <section className="page-header">
        <Figure legend={legend} credit={credit} img={HomeImg} />
      </section>
      <div className="container-fluid">
        <p className="description">
          {formatMessage({ id: "home.description" })}
        </p>
        <Category locale={locale} />
      </div>
    </div>
  );
}

export default Home;
