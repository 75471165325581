import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { localizeRoute } from "../../i18n";

export function LocalizedLink(props) {
  
  const { locale } = useIntl();

  return (
    <Link
      id={props.id}
      to={localizeRoute(locale, props.to)}
      onClick={props.onClick}
      className={props.className}
    >
      {props.children}
    </Link>
  );
};
