import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, Route } from "react-router-dom";
import { useIntl } from "react-intl";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  AppLanguage,
  AppDefaultLanguage,
  AppPreference,
  AppRoute,
} from "./const";
import Header from "./components/Header/Header";
import CookiesConsent from "./components/UI/CookiesConsent";
import ModalShare from "./components/UI/ModalShare";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { LocalizedRoutes } from "./modules/i18n";
import Home from "./components/Home/Home";
import Page from "./components/Page/Page";
import Tour from "./components/Tour/Tour";
import Poi from "./components/Poi/Poi";
import Menu from "./components/Menu/Menu";

// const PAGE_TRANSITION_TIME = 500; // 0.5 sec

function App(props) {
  let location = useLocation();
  const { formatMessage, locale } = useIntl();

  const [menuExpanded, setMenuExpanded] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  
  const nodeRef = useRef(null);
  
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
    setSessionPreferredLanguage();
  }, []);

  function setSessionPreferredLanguage() {
    if (!sessionStorage.getItem(AppPreference.PreferredLanguage)) {
      const lang =
        navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);
      if (Object.values(AppLanguage).includes(lang)) {
        sessionStorage.setItem(AppPreference.PreferredLanguage, lang);
        if (lang !== locale) {
          window.location.replace(`/${lang}/`);
        }
      } else {
        sessionStorage.setItem(
          AppPreference.PreferredLanguage,
          props.intl.locale
        );
      }
    }
  }

  function switchSessionLanguage(lang) {
    const currentLanguage = sessionStorage.getItem(
      AppPreference.PreferredLanguage
    );
    const { pathname } = location;
    sessionStorage.setItem(AppPreference.PreferredLanguage, lang);
    let newPathname = pathname.replace(`${currentLanguage}/`, "");
    if (lang !== AppDefaultLanguage) {
      newPathname = `/${lang}${newPathname}`;
    }
    window.location.replace(newPathname);
    setMenuExpanded(false);
  }

  function ShareModal() {
    setMenuExpanded(false);
    setShowShareModal(true);
  }

  function dismissModal() {
    setShowShareModal(false);
  }

  function toggleMenu(expanded) {
    setMenuExpanded(expanded);
  }

  function closeMenu() {
    setMenuExpanded(false);
  }
  
  const mainClassName = location.pathname.includes("poi") ? "has-player" : null;
  
  return (
    <>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuExpanded={menuExpanded}
        locale={locale}
        formatMessage={formatMessage}
      />
      {/*<TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames="fade"
          timeout={PAGE_TRANSITION_TIME}
          nodeRef={nodeRef}
        >*/}
          <main className={mainClassName} ref={nodeRef}>
            <Menu
              expanded={menuExpanded}
              closeMenuHandler={closeMenu}
              shareModalHandler={ShareModal}
              switchSessionLanguageHandler={switchSessionLanguage}
              locale={locale}
              formatMessage={formatMessage}
            />
            <div id="content">
              {/*<Flag switchSessionLanguage={switchSessionLanguage} locale={locale}
        formatMessage={formatMessage}/>*/}
              <LocalizedRoutes location={location}>
                <Route
                  path={AppRoute.Home}
                  element={
                    <Home
                      locale={locale}
                      formatMessage={formatMessage}
                    />
                  }
                />
                <Route
                  path={AppRoute.Tour}
                  element={
                    <Tour
                      locale={locale}
                      formatMessage={formatMessage}
                    />
                  }
                />
                <Route
                  path={AppRoute.Poi}
                  element={
                    <Poi
                      locale={locale}
                      formatMessage={formatMessage}
                    />
                  }
                />
                <Route
                  path={AppRoute.Credits}
                  element={
                    <Page
                      metaTitle={formatMessage({
                        id: "credits.meta.title",
                      })}
                      metaDescription={formatMessage({
                        id: "credits.meta.description",
                      })}
                      title={formatMessage({
                        id: "credits.title",
                      })}
                      content={formatMessage({
                        id: "credits.content",
                      })}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <Page
                      metaTitle={formatMessage({
                        id: "error.meta.title",
                      })}
                      metaDescription={formatMessage({
                        id: "error.meta.description",
                      })}
                      title={formatMessage({
                        id: "error.title",
                      })}
                      content={formatMessage({
                        id: "error.content",
                      })}
                    />
                  }
                />
              </LocalizedRoutes>
            </div>
          </main>
        {/*</CSSTransition>
      </TransitionGroup>*/}
      <CookiesConsent formatMessage={formatMessage} />
      <ModalShare
        showShareModal={showShareModal}
        dismissModal={dismissModal}
        formatMessage={formatMessage}
      />
    </>
  );
}

export default App;
