import React from "react";

export function Transcription(props) {
  //props
  const poi = props.poi;
  const locale = props.locale;
  
  return (
    <div id="transcription">
      <div
        dangerouslySetInnerHTML={{
          __html: poi.text[locale],
        }}
      ></div>
    </div>
  );
}
