import React from "react";
import {loadMedia} from "../../modules/utils/"


export function Figure(props) {

    const legend = props.legend;
    const credit = props.credit;
    const img = loadMedia(props.img);

    return (
      <figure id="figure">
        <figcaption>{legend + " - ©" + credit}</figcaption>
        <img src={img} alt={legend}/>
      </figure>
    );
}
