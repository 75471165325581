import React from "react";
import ReactDOM from "react-dom/client";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import { instance } from "./modules/analytics/matomo"
import { HelmetProvider } from "react-helmet-async";
import { AppLanguage } from "./const";
import { LocalizedRouter, appStrings } from "./modules/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.min.css";
import App from "./App";
import ScrollToTop from './hoc/ScrollToTop/ScrollToTop';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <HelmetProvider>
        <LocalizedRouter languages={AppLanguage} appStrings={appStrings}>
          <ScrollToTop />
          <App />
        </LocalizedRouter>
      </HelmetProvider>
    </MatomoProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
