import React from "react";
import { generatePath } from "react-router-dom";
import { AppRoute } from "../../const";
import { LocalizedLink } from "../../modules/i18n";

export function Popover(props) {
  //props
  const poi = props.poi;
  const locale = props.locale;
  const categoryId = props.categoryId;
  const tour = props.tour;

  return (
    <div
      style={props.visible ? { display: "block" } : { display: "none" }}
      id="popover"
    >
      {poi ? (
        <LocalizedLink
          to={generatePath(AppRoute.Poi, {
            categoryId: categoryId,
            tourId: tour.id,
            poiId: poi.id,
          })}
        >
          {`${poi.name[locale]}`}
          <span className="info"></span>
        </LocalizedLink>
      ) : (
        ""
      )}
    </div>
  );
}
