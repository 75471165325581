import React from "react";
import { generatePath } from "react-router";
import { AppRoute } from "../../const";
import { LocalizedLink } from "../../modules/i18n";
import * as DataManager from "../../modules/datamanager/";


function Category(props) {
  //props
  const locale = props.locale;;

  const categories = DataManager.getCategories();

  return (
    <section id="category">
      {categories.map((category, id) => {
        return (
          <div key={`${category}-${id}`} id={category.anchor} className="categ">
            <div className="line">
              <h2>{category.name[locale]}</h2>
            </div>
            {category.tours.map((tour, id) => {
              return (
                <div key={`${tour}-${id}`}>
                  <LocalizedLink
                    to={generatePath(AppRoute.Tour, {
                      categoryId: category.id,
                      tourId: tour.id,
                    })}
                  >
                    <div className="row">
                      <div className={`col-12 tours-list bg-${tour.color}`}>
                        <div className="number">{tour.number}</div>
                        <div className="title">
                          {tour.name[locale]}
                          <span className="subtitle">
                            {tour.subtitle[locale]}
                          </span>
                        </div>
                        <div className="info">
                          {/*tour.isSuggested ? "🛣️" : "🗺️"*/}
                        </div>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              );
            })}
          </div>
        );
      })}
    </section>
  );
}

export default Category;
