import React, { useState } from "react";

export function MyMarker(props) {
  
  const width = 24.396;
  const height = 33.31;
  const fontSize = 14;
  const fontFamily = "DolceVitaHeavy-Bold, Dolce Vita A Few Extra Pounds";

  const [internalHover] = useState(props.hover || false);
  const hover =
    typeof props.hover === "undefined" ? internalHover : props.hover;
  const color = props.color || "#93C0D0";
  const text = props.text || "";

  // what do you expect to get back with the event
  const eventParameters = (event) => ({
    event,
    anchor: props.anchor,
    payload: props.payload,
  });

  const measureText = (s) => {
    
    var div = document.createElement('div');
    document.body.appendChild(div);
    div.style.fontFamily = fontFamily;
    div.style.fontSize = `${fontSize}px`;
    div.style.position = "absolute";
    div.style.left = -1000;
    div.style.top = -1000;
    div.textContent = s;

    var res = {
        width: div.clientWidth,
        height: div.clientHeight
    };

    document.body.removeChild(div);
    div = null;

    return res;
  }

  const textSize = measureText(text);

  return (
    <div
      style={{
        position: "absolute",
        transform: `translate(${props.left - width / 2}px, ${
          props.top - (height - 1)
        }px)`,
        filter: hover ? "drop-shadow(0 0 4px rgba(0, 0, 0, .3))" : "",
        pointerEvents: "none",
        cursor: "pointer",
        ...(props.style || {}),
      }}
      className={
        props.className
          ? `${props.className} pigeon-click-block`
          : "pigeon-click-block"
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g
          style={{ pointerEvents: "auto" }}
          onClick={
            props.onClick
              ? (event) => props.onClick(eventParameters(event))
              : null
          }
          id="PICTO_POI"
          data-name="PICTO POI"
        >
          <path
            id="Tracé_11398"
            data-name="Tracé 11398"
            d="M12.2,0A12.2,12.2,0,0,1,24.4,12.2c0,6.737-12.017,21.809-12.2,21.087S0,18.934,0,12.2A12.2,12.2,0,0,1,12.2,0Z"
            fill={color}
          />
          <circle
            id="Ellipse_89"
            data-name="Ellipse 89"
            cx="12"
            cy="12"
            r="9"
            fill="#fff"
          />
          <text
            id={`_${text}`}
            data-name={`${text}`}            
            fill={color}
            fontSize={fontSize}
            fontFamily={fontFamily}
            transform={`translate(${(width / 2) - (textSize.width/2)} 17)`}
          > 
            {text}
          </text>
        </g>
      </svg>
    </div>
  );
}
