import React, { useEffect, useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router";
import { AppConfig, AppDefaultLanguage, AppRoute } from "../../const";
import { localizeRoute } from "../../modules/i18n";
import { getPreviousPoi, getNextPoi } from "../../modules/datamanager";
import { formatTime, loadMedia } from "../../modules/utils";
import { analyticsIsAvailable } from "../../modules/analytics/matomo";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export function Player(props) {
  //Récupération des paramètres
  const locale = props.locale;
  const tour = props.tour;
  const categoryId = props.categoryId;
  const setDocumentSelected = props.setDocumentSelected;
  const poi = props.poi;

  const hasAudio = poi.audio[locale] !== null ? true : false;
  const navigate = useNavigate();

  const { trackEvent } = useMatomo();

  //CreateRef
  const audioRef = createRef();
  const seekRef = createRef();
  const currentTimeRef = createRef();

  //Analytics State Hooks
  const [analyticsItemId, setAnalyticsItemId] = useState(poi.id);
  const [analyticsItemName, setAnalyticsItemName] = useState(
    `${locale}-${poi.name[AppDefaultLanguage]}`
  );

  //State Hooks
  const [sessionCurrentTimeKey, setSessionCurrentTimeKey] = useState(
    `poi_${poi.id}_${locale}_current_time`
  );
  const [nextPoi, setNextPoi] = useState(getNextPoi(tour.id, poi.id));
  const [previousPoi, setPreviousPoi] = useState(
    getPreviousPoi(tour.id, poi.id)
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(formatTime(0));

  const [audioWasPlaying, setAudioWasPlaying] = useState(false);

  //Effect Hooks
  useEffect(() => {
    saveProgress();
    reset();
  }, [poi.id]);

  /**
   *
   */
  function reset() {
    currentTimeRef.current.innerHTML = formatTime(0);
    seekRef.current.value = 0;
    setSessionCurrentTimeKey(`poi_${poi.id}_${locale}_current_time`);
    setNextPoi(getNextPoi(tour.id, poi.id));
    setPreviousPoi(getPreviousPoi(tour.id, poi.id));
    setIsPlaying(false);
    setDuration(formatTime(0));
    setDocumentSelected(null);
    setAnalyticsItemId(poi.id);
    setAnalyticsItemName(`${locale}-${poi.name[AppDefaultLanguage]}`);
    audioRef.current.load();
  }

  /**
   *
   */
  function initializeAudio() {
    const duration = audioRef.current.duration;
    const audioDuration =
      isNaN(duration) || duration === Infinity
        ? poi.length[locale]
        : Math.round(duration);
    seekRef.current.setAttribute("max", audioDuration);
    setDuration(formatTime(audioDuration));

    if (AppConfig.SaveSessionProgress) {
      const sessionCurrentTime = sessionStorage.getItem({
        sessionCurrentTimeKey,
      });
      if (sessionCurrentTime) {
        audioRef.current.currentTime = { sessionCurrentTime };
      }
    }
  }

  /**
   *
   */
  function togglePlay() {
    if (audioRef.current.paused || audioRef.current.ended) {
      audioRef.current.play();
      if (!analyticsIsAvailable()) return;
      trackEvent({
        category: "poi_audio_played",
        action: "click-event",
        name: analyticsItemName,
        customDimensions: [
          {
            id: analyticsItemId,
          },
        ],
      });
    } else {
      audioRef.current.pause();
      if (!analyticsIsAvailable()) return;
      trackEvent({
        category: "poi_audio_paused",
        action: "click-event",
        name: analyticsItemName,
        customDimensions: [
          {
            id: analyticsItemId,
          },
        ],
      });
    }
  }

  /**
   *
   */
  function playerStateDidUpdate() {
    setIsPlaying(!audioRef.current.paused);
  }

  /**
   *
   */
  function updateProgress() {
    seekRef.current.value = audioRef.current.currentTime;
    currentTimeRef.current.innerHTML = formatTime(audioRef.current.currentTime);
    let target = seekRef.current;
    if (target.type !== "range") {
      target = document.getElementById("range");
    }
    const min = target.min;
    const max = target.max;
    const val = target.value;

    target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
  }

  /**
   *
   */
  function saveProgress() {
    if (AppConfig.SaveSessionProgress) {
      const listened =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      if (listened >= 10 && listened <= 80) {
        sessionStorage.setItem(
          sessionCurrentTimeKey,
          audioRef.current.currentTime
        );
      } else {
        sessionStorage.removeItem(sessionCurrentTimeKey);
      }
    }
  }

  /**
   *
   */
  function audioDidFinish() {
    currentTimeRef.current.innerHTML = formatTime(0);
    if (AppConfig.SaveSessionProgress) {
      sessionStorage.removeItem(sessionCurrentTimeKey);
    }
    if (!analyticsIsAvailable()) return;
    trackEvent({
      category: "poi_audio_listened",
      action: "click-event",
      name: analyticsItemName,
      customDimensions: [
        {
          id: analyticsItemId,
        },
      ],
    });
  }

  /**
   *
   */
  function goToPreviousPoi() {
    navigate(
      localizeRoute(
        locale,
        generatePath(AppRoute.Poi, {
          categoryId: categoryId,
          tourId: tour.id,
          poiId: previousPoi.id,
        })
      )
    );
  }

  /**
   *
   */
  function goToNextPoi() {
    navigate(
      localizeRoute(
        locale,
        generatePath(AppRoute.Poi, {
          categoryId: categoryId,
          tourId: tour.id,
          poiId: nextPoi.id,
        })
      )
    );
  }

  /**
   *
   */
  function skipAheadDidBegin() {
    if (!audioRef.current.paused) {
      setAudioWasPlaying(true);
      audioRef.current.pause();
    }
  }

  /**
   *
   * @param {*} event
   */
  function skipAhead(event) {
    const skipTo = event.target.dataset.seek
      ? event.target.dataset.seek
      : event.target.value;
    audioRef.current.currentTime = skipTo;
    seekRef.current.value = skipTo;
  }

  /**
   *
   */
  function skipAheadDidFinish() {
    if (audioWasPlaying) {
      audioRef.current.play();
      setAudioWasPlaying(false);
    }
  }

  function onChange(event) {
    let target = event.target;
    if (target.type !== "range") {
      target = document.getElementById("range");
    }
    const min = target.min;
    const max = target.max;
    const val = target.value;

    target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
  }

  return (
    <div id="player">
      <div className="info">
        <div className="hidden-sm w-100">
          <div className="track">
            <h2>{`${poi.number}. ${poi.name[locale]}`}</h2>
          </div>
        </div>
        <div className={hasAudio ? "time" : "hidden"}>
          <span ref={currentTimeRef} className="current-time">
            {formatTime(0)}
          </span>
          <span className={hasAudio !== null ? "duration" : "hidden"}>
            {duration}
          </span>
        </div>
        <div className={hasAudio !== null ? "timeline" : "hidden"}>
          <input
            id="range"
            ref={seekRef}
            className="seek"
            value="0"
            min="0"
            type="range"
            step="1"
            onInput={skipAhead}
            onPointerDown={skipAheadDidBegin}
            onPointerUp={skipAheadDidFinish}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="controls">
        <audio
          ref={audioRef}
          src={loadMedia(poi.audio[locale])}
          title={poi.name[locale]}
          onLoadedMetadata={initializeAudio}
          onPlay={playerStateDidUpdate}
          onPause={playerStateDidUpdate}
          onEnded={audioDidFinish}
          onTimeUpdate={updateProgress}
          controls={false}
          preload="metadata"
          autoPlay={false}
          muted={false}
          loop={false}
        />
        <button
          onClick={goToPreviousPoi}
          className="previous"
          disabled={previousPoi ? false : true}
        >
          {previousPoi ? `Vers ${previousPoi.number}` : null}
        </button>
        <button
          onClick={togglePlay}
          className={`play ${isPlaying ? "pause" : "play"}`}
          disabled={poi.audio[locale] !== null ? false : true}
        />
        <button
          onClick={goToNextPoi}
          className="next"
          disabled={nextPoi ? false : true}
        >
          {nextPoi ? `Vers ${nextPoi.number}` : null}
        </button>
      </div>
    </div>
  );
}
