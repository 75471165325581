import React from "react";
import { generatePath } from "react-router-dom";

import { LocalizedLink } from "../../modules/i18n";
import { AppRoute } from "../../const";

import appLogo from "../../assets/images/Logo LGM VISITES PWA V2.png";

export function Logo(props) {
  //props
  const formatMessage = props.formatMessage;

  return (
    <h1 id="logo">
      <LocalizedLink to={generatePath(AppRoute.Home)} className="app-logo">
        <img src={appLogo} alt={formatMessage({ id: "app.logo" })} />
      </LocalizedLink>
    </h1>
  );
}