import React from "react";
import { Navbar } from "react-bootstrap";
import { Logo } from "./Logo";

function Header(props) {
  //props
  const locale=props.locale;
  const formatMessage=props.formatMessage;
  const toggleMenu = props.toggleMenu;
  const menuExpanded = props.menuExpanded;
    return (
      <header id="header">
        <Navbar
          expand="lg"
          onToggle={toggleMenu}
          expanded={menuExpanded}
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
        <Logo locale={locale} formatMessage={formatMessage} />
      </header>
    );
}

export default Header;