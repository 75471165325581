import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { loadMedia } from "../../modules/utils";

export function Doc(props) {
  //props
  const poi = props.poi;
  const locale = props.locale;

  const [documentSelected, setDocumentSelected] = useState(null);

  return (
    <>
      <div className="documents-list">
        {poi.documents.map((doc, index) => {
          return (
            <div
              key={`doc-${index}`}
              className="row"
              onClick={() => setDocumentSelected(doc)}
            >
              <div className="col-12 doc">
                <div className="title">{doc.name[locale]}</div>
                <div className={`doc-type ${doc.type}`}></div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        id="CarrousselModal"
        show={documentSelected !== null}
        onHide={() => setDocumentSelected(null)}
        centered={true}
      >
        <Modal.Body>
          {documentSelected ? (
            <figure>
              <figcaption>
                {" "}
                {documentSelected.caption.legend[locale]} - ©{documentSelected.caption.credit}
              </figcaption>
              <img
                src={loadMedia(documentSelected.url[locale])}
                width="100%"
                alt=""
              />
            </figure>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
