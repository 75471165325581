import React, { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { loadMedia } from "../../modules/utils";

export function Carroussel(props) {
  
  const locale = props.locale;

  const [zoomedImage, setZoomedImaged] = useState(null);

  // const handleShow = (image) => setZoomedImaged(image);
  const handleClose = () => setZoomedImaged(null);

  return (
    <>
      <Carousel
        id={props.id}
        className={props.className}
        controls={false}
        interval={props.interval}
        indicators={props.images.length > 1}
      >
        {props.images.map((i, index) => {
          return (
            <Carousel.Item key={`img-${index}`}>
              <img
                src={loadMedia(i.url[locale])}
                className="d-block w-100"
                alt={i.caption.legend[locale]}
              />
              <Carousel.Caption>
                <p className="p-1">
                  {i.caption.legend[locale]} - ©{i.caption.credit}
                </p>
              </Carousel.Caption>
              {/*<button className="zoom" onClick={() => handleShow(i)}></button>*/}
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Modal
        id="zoomImageModal"
        show={zoomedImage !== null}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Body>
          {zoomedImage ? (
            <img
              src={loadMedia(zoomedImage.url[locale])}
              width="100%"
              alt={zoomedImage.caption.legend[locale]}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};
