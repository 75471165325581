import React from "react";
import { AppRoute } from "../../const";
import { LocalizedLink } from "../../modules/i18n";
import { generatePath } from "react-router";


export function PoiLink(props){

    const poi = props.poi;
    const tour = props.tour;
    const categoryId = props.categoryId;
    const locale= props.locale;

    return(

        <div className="row">
          <LocalizedLink
            key={poi.id}
            to={generatePath(AppRoute.Poi, {
              categoryId: categoryId,
              tourId: tour.id,
              poiId: poi.id,
            })}
          >
            <div className={`col-12 poi bg-${poi.color}`}>
              <div className="number">{poi.number}</div>
              <div className="title">{poi.name[locale]}</div>
              <div className="info"></div>
            </div>
          </LocalizedLink>
        </div>

    );
}

