import React from "react";
import { generatePath } from "react-router";
import { AppLanguage, AppRoute } from "../../const";
import { LocalizedLink } from "../../modules/i18n";
import * as DataManager from "../../modules/datamanager/";

function Menu(props) {
  const categories = DataManager.getCategories();

  const locale = props.locale;
  const formatMessage = props.formatMessage;

  return (
    <nav
      id="navbar-left-menu"
      className={`bg-light-blue collapse ${props.expanded ? "show" : ""}`}
    >
      <div
        className={`${props.expanded ? "bg-fume" : ""}`}
        onClick={props.closeMenuHandler}
      ></div>
      <div id="left-menu">
        <ul>
          {categories.map((category, id) => {
            return (
              <li className="menu-item" key={`${category}-${id}`}>
                <LocalizedLink
                  to={
                    generatePath(AppRoute.Home + "#" + category.anchor)
                  }
                  onClick={props.closeMenuHandler}
                >
                  {category.name[locale]}
                </LocalizedLink>
              </li>
            );
          })}
        </ul>
        <ul className="bottom">
          <li className="menu-item">
            <button onClick={props.shareModalHandler}>
              <i className="ic ic-share"></i>
              <span>{formatMessage({ id: "menu.share" })}</span>
            </button>
          </li>
          {Object.keys(AppLanguage).map((key) => {
            const lang = AppLanguage[key];
            const flag =
              locale !== lang ? (
                <li
                  key={`menu-${AppLanguage[key]}`}
                  className="menu-item hidden-lg "
                  id="lang"
                >
                  <button
                    type="button"
                    onClick={props.switchSessionLanguageHandler(lang)}
                  >
                    <i className={`ic flag-${lang}`}></i>
                    {formatMessage({ id: `menu.language.${lang}` })}
                  </button>
                </li>
              ) : null;
            return flag;
          })}
          <li className="menu-item">
            <LocalizedLink
              to={generatePath(AppRoute.Credits)}
              onClick={props.closeMenuHandler}
            >
              <i className="ic ic-copyrights"></i>
              {formatMessage({ id: "menu.credits" })}
            </LocalizedLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Menu;
