import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Map, GeoJson, Overlay, ZoomControl } from "pigeon-maps";
import { osm } from "pigeon-maps/providers";
import { loadKml } from "../../modules/utils";
import { MyMarker } from "./MyMarker.js";
import { UserMarker } from "./UserMarker.js";
import * as datamanager from "../../modules/datamanager";
import { Popover } from "./Popover";

export function TourMap(props) {

  const { formatMessage } = useIntl();

  //props
  const tour = props.tour;
  const kml = tour.KML ? loadKml(tour.KML) : null;
  const categoryId = props.categoryId;
  const locale = props.locale;

  //Boolean
  const hasPoi = tour.pois.length !== 0 ? true : false;
  const hasStartEnd = tour.start && tour.end ? true : false;

  //Start
  const startLatitude = hasStartEnd ? tour.start.latitude : null;
  const startLongitude = hasStartEnd ? tour.start.longitude : null;
  //End
  const endLatitude = hasStartEnd ? tour.end.latitude : null;
  const endLongitude = hasStartEnd ? tour.end.longitude : null;

  const latitudes = hasPoi
    ? tour.pois
        .map((poi) => {
          return poi.latitude;
        })
        .filter((latitude) => latitude !== null)
    : hasStartEnd
    ? [startLatitude, endLatitude]
    : null;

  const longitudes = hasPoi
    ? tour.pois
        .map((poi) => {
          return poi.longitude;
        })
        .filter((longitude) => longitude !== null)
    : hasStartEnd
    ? [startLongitude, endLongitude]
    : null;

  const minLng = Math.min(...longitudes);
  const minLat = Math.min(...latitudes);
  const maxLng = Math.max(...longitudes);
  const maxLat = Math.max(...latitudes);
  const centerLat = (maxLat + minLat) / 2.0;
  const centerLng = (maxLng + minLng) / 2.0;

  const zoomStyle = {
    top: 10,
    left: 'auto',
    bottom: 'auto',
    right: 10
  };

  const zoomButtonStyle = { background: 'white', color: '#112B49' };

  const [center, setCenter] = useState([centerLat, centerLng]);
  const zoom = tour.initialZoom ? tour.initialZoom : 14;

  const [anchor, setAnchor] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [poi, setPoi] = useState(undefined);
  const [userLocation, setUserLocation] = useState(null); // 43.55998663518303, 4.082463318705042
  const [userLocationStatus, setUserLocationStatus] = useState(null); 

  // let locationInterval = null;
  // const locationUpdateTime = 5000;

  useEffect(() => {
    startUpdateLocation()
    return () => {
      stopUpdateLocation()
    }
  }, []);

  function onClickMarker(poiId, categoryId, [latitude, longitude]) {
    const poi = datamanager.getPoiById(poiId);
    setVisible(true);
    setAnchor([latitude, longitude]);
    setPoi(poi);
    setCenter([latitude, longitude]);
  }

  function onClickMap() {
    setVisible(false);
  }

  function startUpdateLocation() {
      updateLocation();
      /*locationInterval = setInterval(function () {
        updateLocation();
      }, locationUpdateTime);*/
  }

  function updateLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError);
    }
  }

  function onLocationSuccess(position) {
    setUserLocation(position);
  }

  function onLocationError(error) {
    setUserLocationStatus(error);
    stopUpdateLocation();
  }

  function stopUpdateLocation() {
    /*if (locationInterval) {
      clearInterval(locationInterval);
    }*/
  }

  return (
    <>
      <Map
        minZoom={10}
        maxZoom={18}
        twoFingerDrag={false}
        metaWheelZoom={true}
        zoomSnap={false}
        provider={osm}
        center={center}
        zoom={zoom}
        onClick={() => onClickMap()}
      >
        <ZoomControl style={zoomStyle} buttonStyle={zoomButtonStyle} />
        {kml ? (
          <GeoJson
            data={kml.data}
            styleCallback={(feature, hover) => {
              if (
                feature.geometry.type === "LineString" ||
                feature.geometry.type === "MultiPolygon"
              ) {
                return { strokeWidth: "2.5", stroke: "#FF8223" };
              }
              return {
                fill: "#d4e6ec99",
                strokeWidth: "1",
                stroke: "white",
                r: "20",
              };
            }}
          />
        ) : (
          ""
        )}
        {hasPoi ? (
          tour.pois.map((poi) => {
            const latitude = poi.latitude;
            const longitude = poi.longitude;
            return (
              <MyMarker
                key={`${tour.id}-${poi.id}`}
                color="#112B49"
                className="marker"
                text={`${poi.number}`}
                anchor={[latitude, longitude]}
                onClick={() =>
                  onClickMarker(poi.id, categoryId, [latitude, longitude])
                }
              />
            );
          })
        ) : hasStartEnd ? (
          <MyMarker
            key={`${tour.id}-start`}
            color="#112B49"
            className="marker"
            text=""
            anchor={[startLatitude, startLongitude]}
          />
        ) : (
          ""
        )}
        {userLocation &&
          <UserMarker 
            className="marker-current-location"
            anchor={[userLocation.coords.latitude, userLocation.coords.longitude]} 
          />
        }
        <Overlay anchor={anchor} offset={[110, 75]}>
          <Popover
            visible={visible}
            poi={poi}
            locale={locale}
            categoryId={categoryId}
            tour={tour}
          ></Popover>
        </Overlay>
      </Map>
      {userLocationStatus && 
        <div className="location-status">
          <p>{ formatMessage({ id: "location.unavailable_msg" }) }</p>
        </div>
      }
    </>
  );
}
