import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";


function ModalShare(props) {

  const showShareModal = props.showShareModal;
  const dismissModal = props.dismissModal;
  const {formatMessage} = useIntl();
  
  return (
    <Modal
      id="shareModal"
      show={showShareModal}
      onHide={dismissModal}
      centered={true}
    >
      <Modal.Header>
        <h5 className="modal-title text-uppercase">
          <i className="ic ic-share"></i>
          {formatMessage({ id: "share.title" })}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <a
            href={formatMessage({ id: "share.twitter" })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="img-thumbnail fab fa-twitter fa-2x"
              style={{
                color: "#4c6ef5",
                backgroundColor: "#f0f8ff",
              }}
            />
            <p>Twitter</p>
          </a>
        </div>
        <div>
          <a
            href={formatMessage({ id: "share.facebook" })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="img-thumbnail fab fa-facebook fa-2x"
              style={{
                color: "#3b5998",
                backgroundColor: "#eceff5",
              }}
            />
            <p>Facebook</p>
          </a>
        </div>
        <div>
          <a
            href={formatMessage({ id: "share.email" })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="img-thumbnail far fa-envelope fa-2x" />
            <p>Email</p>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalShare;
