import React, { useState } from 'react';
import { AppPreference } from '../../const';
import {useIntl} from "react-intl";

function CookiesConsent () {

  const {formatMessage} = useIntl();

  const [hidden, setHidden] = useState(localStorage.getItem(AppPreference.CookiesConsent) !== null);

  const setCookiesConsent = (value) => {
    setHidden(true);
    localStorage.setItem(AppPreference.CookiesConsent, value.toString());
  };

  return (
    !hidden ? 
      <div id="cookies-consent">
        <p>
          {formatMessage({ id: "consent.msg"})} 
          <br />
          <button className="btn btn-success btn-small" onClick={() => setCookiesConsent(true)}>{formatMessage({ id: "consent.ok"})}</button>
          <button className="btn btn-danger btn-small" onClick={() => setCookiesConsent(false)}>{formatMessage({ id: "consent.no"})}</button>
        </p>
      </div>
    :
      null
  );
}

export default CookiesConsent;