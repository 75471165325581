import React from "react";
import { generatePath, useParams } from "react-router";
import { LocalizedLink } from "../../modules/i18n";
import { AppRoute } from "../../const";

export function Title(props) {
  const params = useParams();
  const categoryId = params.categoryId;
  const className = props.className ? props.className : "";

  const tour = props.tour;
  const poi = props.poi;
  const locale = props.locale;

  return (
    <div id="poi-title" className={`title ${className} bg-yellow`}>
      <LocalizedLink
        id="back"
        className={`bg-${tour.color}`}
        to={generatePath(AppRoute.Tour, {
          categoryId: categoryId,
          tourId: tour.id,
        })}
      ></LocalizedLink>
      <div className={`number tour-number bg-${tour.color}`}>
        <span>{tour.number}</span>
      </div>
      <div className={`number poi-number bg-${poi.color}`}>
        <span>{poi.number}</span>
      </div>
      <div className={`h1 poi-h1 bg-${poi.color}`}>
        <h1>{poi.name[locale]}</h1>
      </div>
    </div>
  );
}
