export var data = {
  type: "FeatureCollection",
  name: "Running 5km au ponant",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "Ligne 9", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.094966, 43.563632],
          [4.100259, 43.565817],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 11", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.100259, 43.565817],
          [4.1005117, 43.5659194],
          [4.1004848, 43.5660137],
          [4.1004245, 43.5661857],
          [4.1003883, 43.5662731],
          [4.100332, 43.5663858],
          [4.100281, 43.5664947],
          [4.1002434, 43.5665782],
          [4.1001911, 43.566723],
          [4.100175, 43.5667745],
          [4.1001362, 43.5670281],
          [4.1001402, 43.5676228],
          [4.100152, 43.567719],
          [4.1004647, 43.5675966],
          [4.100576, 43.5673673],
          [4.1004781, 43.567239],
          [4.1007343, 43.566896],
          [4.1005452, 43.5667736],
          [4.1001818, 43.5667512],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 12", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.1005117, 43.5659194],
          [4.1005521, 43.5656874],
          [4.1005548, 43.5655825],
          [4.1005682, 43.5651627],
          [4.1005521, 43.5649878],
          [4.1005334, 43.5648964],
          [4.1005119, 43.5647876],
          [4.1005012, 43.564669],
          [4.1004985, 43.564601],
          [4.1005012, 43.564531],
          [4.1005119, 43.5644533],
          [4.1005307, 43.5643969],
          [4.1005495, 43.5643192],
          [4.1005843, 43.5642473],
          [4.1006219, 43.5641812],
          [4.1006594, 43.5641268],
          [4.1007292, 43.5640432],
          [4.1007506, 43.5640199],
          [4.1009571, 43.5640976],
          [4.1010403, 43.5637031],
          [4.1010752, 43.5630423],
          [4.1010671, 43.5629742],
          [4.101276, 43.562031],
          [4.1013058, 43.5619072],
          [4.1014239, 43.5615651],
          [4.1014614, 43.5614097],
          [4.1014802, 43.5613863],
          [4.1018986, 43.561328],
          [4.1019362, 43.5613552],
          [4.1023948, 43.5613708],
          [4.1024485, 43.5613649],
          [4.1024967, 43.5613494],
          [4.1025557, 43.5613397],
          [4.102701, 43.561352],
          [4.1028106, 43.5613611],
          [4.1029098, 43.5613572],
          [4.1035508, 43.5611784],
          [4.1036206, 43.5612386],
          [4.1036662, 43.5613066],
          [4.1039478, 43.5612658],
          [4.1040256, 43.5612658],
          [4.1043126, 43.5613047],
          [4.1044011, 43.5613125],
          [4.1045969, 43.5612853],
          [4.1046559, 43.5612289],
          [4.1046801, 43.5611784],
          [4.1047283, 43.561122],
          [4.1049563, 43.5610715],
          [4.1050878, 43.5610559],
          [4.105297, 43.5610637],
          [4.1054794, 43.5610851],
          [4.105525, 43.5610695],
          [4.1055437, 43.5610365],
          [4.1055491, 43.5610054],
          [4.1055652, 43.5609646],
          [4.1057476, 43.5605894],
          [4.1057449, 43.5605583],
          [4.1057449, 43.5605234],
          [4.105651, 43.5603523],
          [4.1055196, 43.5600491],
          [4.1052433, 43.5597323],
          [4.1050904, 43.5596312],
          [4.1048732, 43.5595846],
          [4.1045245, 43.5595904],
          [4.1044494, 43.5595748],
          [4.1043877, 43.5595807],
          [4.1043206, 43.5595224],
          [4.1042375, 43.5594796],
          [4.1038673, 43.5593863],
          [4.103634, 43.5593124],
          [4.1034731, 43.5592755],
          [4.1032183, 43.5591686],
          [4.103068, 43.5591744],
          [4.1021266, 43.5593377],
          [4.1020059, 43.5593863],
          [4.1019335, 43.5595671],
          [4.1018932, 43.5597381],
          [4.1018369, 43.5599694],
          [4.1017725, 43.5602804],
          [4.1017216, 43.5605681],
          [4.1016304, 43.5609179],
          [4.1015848, 43.5612133],
          [4.1015016, 43.5613455],
          [4.101448, 43.5614485],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 13", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.1007506, 43.5640199],
          [4.1006134, 43.5639609],
          [4.1006214, 43.5637433],
          [4.1006348, 43.5623147],
          [4.1006804, 43.5620076],
          [4.1007073, 43.5616792],
          [4.1006751, 43.5613798],
          [4.1006858, 43.5610805],
          [4.1007207, 43.5607229],
          [4.1007207, 43.5605227],
          [4.1006912, 43.5601728],
          [4.1007153, 43.5600115],
          [4.1007207, 43.559685],
          [4.100718, 43.5594012],
          [4.1007314, 43.5591349],
          [4.1007663, 43.5589542],
          [4.1009111, 43.5584468],
          [4.1009326, 43.5581942],
          [4.1009031, 43.5579959],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 14", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.1005342, 43.5658],
          [4.1003947, 43.5657495],
          [4.100259, 43.565817],
        ],
      },
    },
  ],
};
	
