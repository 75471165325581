//imports dependencies
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
//imports modules & const
import * as DataManager from "../../modules/datamanager/";
//imports components
import { Title } from "./Title";
import { Carroussel } from "./Carroussel";
import { Player } from "./Player";
import { Tabs } from "./Tabs";
import { TabsContent } from "./TabsContent";
import { TourMap } from "../Map/TourMap";

/**
 *
 * @param {*} props
 * @returns
 */
function Poi(props) {
  //props
  const locale = props.locale;
  const formatMessage = props.formatMessage;
  const trackEvent = props.trackEvent;

  //Navigation Hooks
  const params = useParams();

  //DataManager
  const poi = DataManager.getPoiById(params.poiId);
  const tour = params.tourId ? DataManager.getTourById(params.tourId) : null;
  const categoryId = params.categoryId;

  const [documentSelected, setDocumentSelected] = useState(null);

  return (
    <div id="poi" className="container-fluid scroll-wrapper">
      <Helmet>
        <title>{`${poi.number}. ${poi.name[locale]} - ${tour.name[locale]} | ${formatMessage({ id: "app.name" })}`}</title>
        <meta
          name="description"
          content={`${poi.name[locale]} : ${poi.description[locale]}`}
        />
      </Helmet>

      <div id="top" className="row hidden-lg">
        <Title tour={tour} poi={poi} locale={locale} className="hidden-lg" />
      </div>

      <Carroussel
        id="poiCarroussel"
        className="hidden-lg"
        images={[poi.images]}
        interval={null}
        locale={locale}
      />

      <Player
        locale={locale}
        poi={poi}
        trackEvent={trackEvent}
        tour={tour}
        setDocumentSelected={setDocumentSelected}
        categoryId={categoryId}
      />

      <Tabs 
        formatMessage={formatMessage}
        docsTabEnabled={poi.documents.length > 0} />

      <TabsContent
        poi={poi}
        tour={tour}
        locale={locale}
        categoryId={categoryId}
        formatMessage={formatMessage}
        documentSelected={documentSelected}
        setDocumentSelected={setDocumentSelected}
      />

      <div id="map" className="hidden-lg">
        <TourMap
          tour={tour}
          poi={poi}
          locale={locale}
          categoryId={categoryId}
        />
      </div>

    </div>
  );
}

export default Poi;