import { AppDefaultLanguage } from "../../const";

import * as localizations from "./localizations";

export const appStrings = localizations;

export const localizeRoute = (locale, path) => {
  return locale === AppDefaultLanguage ? path : `/${locale}` + path;
};

export * from "./components/LocalizedRouter";
export * from "./components/LocalizedRoutes";
export * from "./components/LocalizedLink";
