import JSPath from "jspath";
import { AppPreference } from "../../const";

import app from "../../assets/lgm.json";

export const getCategories = () => {
  const categories = JSPath.apply(".categories", app);
  return categories;
};

export const getToursByCategory = (id) => {
  const tours = JSPath.apply(".categories{.id == " + id + "}.tours", app);
  return tours;
};

export const getTourById = (id) => {
  const res = JSPath.apply(".categories.tours{.id == " + id + "}", app);
  const tour = res.length === 1 ? res[0] : null;
  return tour;
};

export const getPoiById = (id) => {
  const res = JSPath.apply(".categories.tours.pois{.id == " + id + "}", app);
  const poi = res.length === 1 ? res[0] : null;
  return poi;
};

export const getPreviousPoi = (tourId, poiId) => {
  const pois = JSPath.apply(
    ".categories.tours{.id == " + tourId + "}.pois",
    app
  );
  const currentPoiIndex = pois.findIndex((p) => {
    return p.id === poiId;
  });
  return currentPoiIndex > 0 ? pois[currentPoiIndex - 1] : null;
};

export const getNextPoi = (tourId, poiId) => {
  const pois = JSPath.apply(
    ".categories.tours{.id == " + tourId + "}.pois",
    app
  );
  
  const currentPoiIndex = pois.findIndex((p) => {
    return p.id === poiId;
  });
  return currentPoiIndex < pois.length - 1 ? pois[currentPoiIndex + 1] : null;
};

export const getNumberOfDocuments = () => {

  const documents = JSON.parse(localStorage.getItem(AppPreference.SavedDocuments)) || 0;
  return documents.length;
}

export const getSaveDocuments = () => {

  let documents = [];
  const docIds = JSON.parse(localStorage.getItem(AppPreference.SavedDocuments)) || [];
  docIds.forEach(id => {
    const res = JSPath.apply(
      '.categories.tours.pois.documents{.id === "' + id + '"}',
      app
    );
    const document = res.length === 1 ? res[0] : null;
    if (document !== null) {
      documents.push(document);
    }
  });
  return documents;
}

export const hasDocument = (docId) => {

  const documents = JSON.parse(localStorage.getItem(AppPreference.SavedDocuments)) || [];
  return documents.includes(docId);
}

export const saveDocument = (docId) => {

  var documents = JSON.parse(localStorage.getItem(AppPreference.SavedDocuments)) || [];
  if (!documents.includes(docId)) {
    documents.push(docId);
  }
  localStorage.setItem(AppPreference.SavedDocuments, JSON.stringify(documents));
}

export const removeDocument = (docId) => {

  if (hasDocument(docId)) {
    const documents = JSON.parse(localStorage.getItem(AppPreference.SavedDocuments));
    const index = documents.indexOf(docId);
    documents.splice(index, 1);
    localStorage.setItem(AppPreference.SavedDocuments, JSON.stringify(documents));
  }
}