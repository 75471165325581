import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { AppLanguage, AppDefaultLanguage, AppRoute } from "../../../const";
import { locales, translations } from "../I18n.js";

export function LocalizedRouter(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoute.Home} element={<Lang prevProps={props} />}>
          {locales.map((lang) => {
            return (
              <Route
                key={`${lang}`}
                path={`:${lang}/*`}
                element={<Lang prevProps={props} />}
              />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Lang(props) {
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  /**
   * Get current language
   * Set default locale to en if base path is used without a language
   */
  params = params.fr;
  const { pathname } = location;

  const lang = params && params.match("fr") ? params : AppDefaultLanguage;

  /**
   * If language in route path is not supported, redirect to default language
   */
  const isSupportedLanguage = Object.values(AppLanguage).includes(lang);
  useEffect(() => {
    if (!isSupportedLanguage) {
      const to = pathname.replace(`/${lang}`, `/${AppDefaultLanguage}`);
      return navigate(to);
    }
  }, [!isSupportedLanguage]);

  /**
   * If default language is in route path, clean route
   */
  const isDefaultLanguage =
    pathname.includes(`/${lang}`) && lang === AppDefaultLanguage;
  useEffect(() => {
    if (isDefaultLanguage) {
      const to = pathname.replace(`/${lang}`, "");
      return navigate(to);
    }
  }, [isDefaultLanguage]);

  /**
   * Return Intl provider with default language set
   */
  return (
    <IntlProvider locale={lang} messages={translations[lang]}>
      {props.prevProps.children}
    </IntlProvider>
  );
}
