import { createInstance } from "@datapunt/matomo-tracker-react";
import { AppPreference } from "../../const";

const analyticsEnabled = process.env.NODE_ENV === "production";

// DOC
// POUR CE PROJET https://www.npmjs.com/package/@datapunt/matomo-tracker-react
// AUTRE https://github.com/jonkoops/matomo-tracker/tree/main/packages/react

export const instance = createInstance({
  //urlBase: "https://analytics.cheztoi.eu/",
  //siteId: 2,
  //trackerUrl: "https://analytics.cheztoi.eu/matomo.php", // optional, default value: `${urlBase}matomo.php`
  //srcUrl: "https://analytics.cheztoi.eu/matomo.js", // optional, default value: `${urlBase}matomo.js`

  //urlBase: "http://matomo/",
  urlBase: "https://matomo.lagrandemotte.com/",
  siteId: 1,
  userId: undefined, // optional, default value: `undefined`.
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: process.env.NODE_ENV === "production",
    setRequestMethod: "POST",
  },
});

export function analyticsIsAvailable() {
  return (
    analyticsEnabled &&
    localStorage.getItem(AppPreference.CookiesConsent) === "true"
  );
}
