import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import * as DataManager from "../../modules/datamanager/";
import { PoiLink } from "./PoiLink";
import { Title } from "./Title";
import { TourMap } from "../Map/TourMap";
import { Breadcrumb } from "../Poi/Breadcrumb";

function Tour(props) {
  
  const locale = props.locale;
  const formatMessage  = props.formatMessage;

  const params = useParams();

  const tour = params.tourId ? DataManager.getTourById(params.tourId) : null;

  return (
    <section id="tour" className="scroll-wrapper">
      <Helmet>
        <title>{`${tour.name[locale]} | ${formatMessage({ id: "app.name" })}`}</title>
        <meta name="description" content={tour.description[locale]} />
      </Helmet>
      <div id="top" className="row">
        <Breadcrumb
          tour={tour}
          locale={locale}
          formatMessage={formatMessage}
          categoryId={params.categoryId}
        />
        <Title tour={tour} locale={locale} />
      </div>
      <div id="map">
        <TourMap locale={locale} tour={tour} categoryId={params.categoryId} />
      </div>
      <div className="pois-list container-fluid">
        {tour.description[locale] !== "" ? <div className="description-tour">
          <p dangerouslySetInnerHTML={{ __html: tour.description[locale] }}></p>
        </div> : null}
        {tour.pois.length !== 0 ? (
          <div className="line">
            <h2>{formatMessage({ id: "home.title" })}</h2>
          </div>
        ) : (
          <></>
        )}

        {tour.pois.map((poi) => {
          return (
            <React.Fragment key={`${tour.id}-${poi.id}`}>
              <PoiLink
                poi={poi}
                tour={tour}
                categoryId={params.categoryId}
                locale={locale}
              />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
}

export default Tour;
