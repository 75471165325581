import React from "react";

export function Tabs(props) {
  const formatMessage = props.formatMessage;
  return (
    <>
      <b id="tab-0" className="targets"></b>
      <b id="tab-1" className="targets"></b>
      <div id="tabs-container" className="row hidden-lg">
        <ul>
          <li id="tab-list-0">
            <a href="#tab-0">{formatMessage({ id: "poi.transcription" })}</a>
          </li>
          { props.docsTabEnabled ?
            <li id="tab-list-1">
              <a href="#tab-1">{formatMessage({ id: "poi.more" })}</a>
            </li>
            :
            null
          }
        </ul>
      </div>
    </>
  );
}
