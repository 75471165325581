export var data = {
  type: "FeatureCollection",
  name: "Le voyage exotique",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "Ligne 13", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.0811959, 43.560627],
          [4.079211, 43.5598806],
          [4.0790179, 43.5594607],
          [4.0791751, 43.5592536],
          [4.0817736, 43.5603397],
          [4.0817967, 43.560627],
          [4.0821129, 43.5607799],
          [4.0819466, 43.5610695],
          [4.085305, 43.562384],
          [4.085429, 43.5622333],
          [4.0844607, 43.5618562],
          [4.0855089, 43.5602071],
        ],
      },
    },
  ],
};