import React from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { Figure } from "../Home/Figure.js";

function Page(props) {
  const { formatMessage } = useIntl();
  const HomeImg = "Accueil.jpg";

  const legend = formatMessage({ id: "home.image.title" });
  const credit = formatMessage({ id: "home.image.credit" });

  return (
    <div className="text-page scroll-wrapper">
      <Helmet>
        <title>{`${props.metaTitle} | ${formatMessage({ id: "app.name" })}`}</title>
        <meta name="description" content={props.metaDescription} />
      </Helmet>
      <section className="page-header">
        <Figure legend={legend} credit={credit} img={HomeImg} />
      </section>
      <div>
        <h1 className="h1-page text-center">{props.title}</h1>
      </div>
      <div
        className="container-fluid pt-3"
        dangerouslySetInnerHTML={{ __html: props.content }}
      ></div>
    </div>
  );
}

export default Page;
