export var data = {
  type: "FeatureCollection",
  name: "Les arbres remarquables - Partie 1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "Ligne 9", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.0983962, 43.5593712],
          [4.0985572, 43.5591613],
          [4.0993082, 43.5591691],
          [4.0993726, 43.5592235],
          [4.099512, 43.5592468],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 10", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.099512, 43.5592468],
          [4.0997266, 43.5592779],
          [4.0998983, 43.5591846],
          [4.1000378, 43.5590836],
          [4.1001021, 43.5589903],
          [4.1003918, 43.5590525],
          [4.1007137, 43.5590758],
          [4.1007029, 43.5600554],
          [4.1006815, 43.5613149],
          [4.1007137, 43.5617969],
          [4.1006386, 43.5622789],
          [4.1006171, 43.5639737],
          [4.1007244, 43.5640281],
          [4.1006708, 43.5641214],
          [4.1005313, 43.5643546],
          [4.1004991, 43.5645645],
          [4.100419, 43.564817],
          [4.100542, 43.5649843],
          [4.1005742, 43.5656218],
          [4.1004991, 43.5660338],
          [4.1003274, 43.5664225],
          [4.1001987, 43.5666791],
          [4.1001558, 43.5670366],
          [4.1001343, 43.5677285],
          [4.1000914, 43.5681483],
          [4.0998339, 43.568537],
          [4.0997481, 43.5686536],
          [4.099555, 43.5687935],
          [4.0990078, 43.5687779],
          [4.09823, 43.568737],
          [4.0964114, 43.5683893],
          [4.0952849, 43.5680744],
          [4.0943461, 43.5673631],
          [4.0937292, 43.5671494],
          [4.0939384, 43.5668656],
          [4.0920126, 43.5660416],
          [4.0919428, 43.5659911],
          [4.0909558, 43.5660299],
          [4.0906554, 43.5664264],
          [4.0904569, 43.5664225],
          [4.090226, 43.566277],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Name: "Ligne 11", description: null, tessellate: 1 },
      geometry: {
        type: "LineString",
        coordinates: [
          [4.0914095, 43.5660109],
          [4.0914068, 43.5661606],
          [4.0908731, 43.5668408],
          [4.0895078, 43.5666309],
          [4.090004, 43.5672101],
          [4.0891296, 43.5676337],
          [4.0887032, 43.5678708],
          [4.0890545, 43.5681293],
          [4.0892262, 43.5681546],
          [4.0892986, 43.5682148],
          [4.0892798, 43.5683197],
          [4.0893281, 43.5684325],
          [4.089588, 43.568679],
          [4.089194, 43.5683761],
          [4.0889955, 43.568347],
          [4.0888561, 43.5684014],
          [4.088671, 43.5686054],
          [4.0883277, 43.5690718],
          [4.088105, 43.5693575],
          [4.0880112, 43.569408],
          [4.0878368, 43.5694644],
          [4.0876893, 43.5694799],
          [4.0875015, 43.5694683],
          [4.087177, 43.5694042],
          [4.0863053, 43.569237],
          [4.0857206, 43.5691243],
          [4.085436, 43.569433],
          [4.0856294, 43.5690893],
          [4.0853665, 43.56893],
          [4.0852538, 43.568825],
          [4.0851251, 43.56879],
          [4.0845082, 43.5686773],
          [4.0831725, 43.5683897],
          [4.0808604, 43.5679699],
          [4.0799216, 43.5677834],
          [4.0796427, 43.5676784],
          [4.0795032, 43.5675851],
          [4.0793744, 43.5674452],
          [4.0792993, 43.5673169],
          [4.0798787, 43.5663841],
          [4.0801952, 43.5660265],
          [4.08012, 43.565838],
          [4.0802864, 43.5659099],
          [4.0803454, 43.5657933],
          [4.0807692, 43.5655328],
          [4.0810642, 43.565389],
          [4.0812842, 43.5650781],
          [4.0815095, 43.5649031],
          [4.0818099, 43.5647321],
          [4.0821478, 43.5646155],
          [4.0824858, 43.5645222],
          [4.0828881, 43.5644872],
          [4.0831295, 43.5644911],
          [4.0833495, 43.5645222],
          [4.0834621, 43.5645611],
          [4.0834836, 43.5644678],
          [4.0835801, 43.564359],
          [4.0838591, 43.5641296],
          [4.0839288, 43.563978],
          [4.0840361, 43.5636088],
          [4.0841059, 43.5633988],
          [4.0843043, 43.5632783],
          [4.084417, 43.5631073],
          [4.0844331, 43.5628857],
          [4.0849373, 43.5628508],
          [4.0853719, 43.5623182],
          [4.0865681, 43.5627225],
          [4.0868417, 43.5627575],
          [4.0874854, 43.5629674],
          [4.0874694, 43.5630373],
          [4.0876839, 43.5631656],
          [4.0879092, 43.5632395],
          [4.0881399, 43.5632472],
          [4.0883438, 43.5632084],
          [4.0884993, 43.5630218],
          [4.0886281, 43.5629363],
          [4.0887675, 43.5629324],
          [4.0889821, 43.5625398],
          [4.0892664, 43.5622016],
          [4.0894327, 43.5620034],
          [4.0894327, 43.5619101],
          [4.0894113, 43.5618401],
          [4.0894917, 43.5617312],
          [4.0904091, 43.5621005],
          [4.0905753, 43.562085],
          [4.0908328, 43.5621472],
          [4.090854, 43.562242],
          [4.0914927, 43.5623493],
          [4.0921042, 43.5625476],
          [4.0927855, 43.562703],
          [4.0951405, 43.5636826],
          [4.0952424, 43.5633561],
          [4.0952853, 43.5631967],
          [4.0955213, 43.5628741],
          [4.0965889, 43.5616302],
          [4.0974579, 43.5605612],
          [4.0974794, 43.5604757],
          [4.0974954, 43.5604135],
          [4.0978227, 43.5601414],
          [4.0978495, 43.5600131],
          [4.098166, 43.5595621],
          [4.0983962, 43.5593712],
          [4.098482, 43.559426],
        ],
      },
    },
  ],
};
