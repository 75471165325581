export var data = {
  type: "FeatureCollection",
  name: "Les arbres remarquables - Partie 2",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "Polygone 10", description: null },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [4.0844755, 43.5620415],
              [4.0843574, 43.5620026],
              [4.084361, 43.5619981],
              [4.0844755, 43.5620415],
            ],
          ],
          [
            [
              [4.084361, 43.5619981],
              [4.081862, 43.561051],
              [4.0806721, 43.5626129],
              [4.0803717, 43.5624418],
              [4.0801893, 43.5626673],
              [4.0795134, 43.5623952],
              [4.078783, 43.564756],
              [4.0779255, 43.5660374],
              [4.0767936, 43.5658236],
              [4.0762464, 43.5650967],
              [4.0761123, 43.5649063],
              [4.0762357, 43.5648246],
              [4.0760051, 43.5645098],
              [4.0748893, 43.5642766],
              [4.0737305, 43.5645642],
              [4.0734033, 43.5646381],
              [4.0730653, 43.5645098],
              [4.0727971, 43.5643815],
              [4.0722285, 43.5632815],
              [4.0721266, 43.5632348],
              [4.0718637, 43.5631882],
              [4.0716277, 43.5632465],
              [4.0714346, 43.5634292],
              [4.0710698, 43.5636352],
              [4.0709249, 43.5636313],
              [4.0707587, 43.5635497],
              [4.0696053, 43.5630871],
              [4.0686504, 43.5625857],
              [4.0682106, 43.5624224],
              [4.067878, 43.562403],
              [4.0675883, 43.5622669],
              [4.0677975, 43.5620726],
              [4.0685002, 43.5615867],
              [4.0688221, 43.5612834],
              [4.068983, 43.5612135],
              [4.069262, 43.5611824],
              [4.069101, 43.5610308],
              [4.0681462, 43.5603583],
              [4.068043, 43.56039],
              [4.067776, 43.5604671],
              [4.0674327, 43.5605021],
              [4.0668373, 43.5604982],
              [4.0665476, 43.5606693],
              [4.066392, 43.5607937],
              [4.0661667, 43.5609103],
              [4.0658127, 43.5610347],
              [4.0655069, 43.5610502],
              [4.0653567, 43.5610075],
              [4.0652816, 43.5608947],
              [4.065318, 43.56071],
              [4.0654479, 43.5604788],
              [4.0657107, 43.5602883],
              [4.0658985, 43.56023],
              [4.0661828, 43.5602067],
              [4.0663169, 43.5601134],
              [4.0674649, 43.5601017],
              [4.0677278, 43.5601367],
              [4.0680872, 43.5603039],
              [4.0692995, 43.5611629],
              [4.0703939, 43.5603388],
              [4.0706514, 43.5602144],
              [4.0708284, 43.5602417],
              [4.0706514, 43.5600201],
              [4.069675, 43.5580569],
              [4.0706353, 43.557987],
              [4.0712736, 43.5580608],
              [4.0737788, 43.5579131],
              [4.0740256, 43.5578509],
              [4.0747122, 43.5578509],
              [4.0748088, 43.5579053],
              [4.0750502, 43.5580064],
              [4.0752111, 43.558123],
              [4.0754418, 43.5580764],
              [4.0755705, 43.5581891],
              [4.0757529, 43.5583329],
              [4.0777968, 43.5591765],
              [4.0784888, 43.5594681],
              [4.080003, 43.560106],
              [4.0813051, 43.5607626],
              [4.0814177, 43.5608209],
              [4.0836386, 43.5580064],
              [4.0837566, 43.558022],
              [4.0846847, 43.5584107],
              [4.0854625, 43.5574038],
              [4.085543, 43.5572989],
              [4.085539, 43.557198],
              [4.0858112, 43.5572445],
              [4.0859507, 43.5572328],
              [4.0860687, 43.5571239],
              [4.0861706, 43.5570967],
              [4.0861974, 43.5569645],
              [4.0863584, 43.5566652],
              [4.0877531, 43.5565797],
              [4.0885417, 43.5565525],
              [4.0892176, 43.5565058],
              [4.0903334, 43.5564203],
              [4.091696, 43.556257],
              [4.091932, 43.5563348],
              [4.093056, 43.556825],
              [4.093772, 43.5571356],
              [4.0947161, 43.5570501],
              [4.0982406, 43.5585662],
              [4.0979723, 43.5589044],
              [4.0948878, 43.5576099],
              [4.0943245, 43.5578354],
              [4.0940778, 43.5578937],
              [4.0938149, 43.5579287],
              [4.0937398, 43.5582435],
              [4.0938471, 43.5584068],
              [4.0937666, 43.5586945],
              [4.0936969, 43.5588927],
              [4.0933965, 43.5595108],
              [4.0932195, 43.5597829],
              [4.0930103, 43.5600201],
              [4.0927206, 43.5602922],
              [4.092388, 43.5606848],
              [4.0920017, 43.5611318],
              [4.0921734, 43.5612368],
              [4.0923022, 43.5613962],
              [4.0922002, 43.5620026],
              [4.0920608, 43.5622397],
              [4.091991, 43.5625118],
              [4.090854, 43.562242],
              [4.0905641, 43.5620803],
              [4.0904085, 43.5621037],
              [4.0899257, 43.5618782],
              [4.0896575, 43.5617694],
              [4.0894805, 43.5617383],
              [4.0879409, 43.561194],
              [4.0879248, 43.561023],
              [4.0875654, 43.5611746],
              [4.087302, 43.56121],
              [4.0868895, 43.561194],
              [4.0863262, 43.561023],
              [4.0854089, 43.5606848],
              [4.084361, 43.5619981],
            ],
          ],
        ],
      },
    },
  ],
};