import React from "react";

import { generatePath } from "react-router";
import { AppRoute } from "../../const";
import { LocalizedLink } from "../../modules/i18n";

import iconHome from "../../assets/images/ic-home.svg";

export function Breadcrumb(props) {
  //Récupération des paramètres
  const poi = props.poi;
  const tour = props.tour;
  const formatMessage = props.formatMessage;
  const locale = props.locale;
  const categoryId=props.categoryId;

  return (
    <div id="breadcrumb" className={`w-100 bg-${tour.color}`}>
      <ul>
        <li>
          <LocalizedLink to={generatePath(AppRoute.Home)}>
            <img src={iconHome} alt={formatMessage({ id: "menu.home" })} />
          </LocalizedLink>
        </li>
        <li>
          <LocalizedLink to={generatePath(AppRoute.Home)}>
            {formatMessage({ id: "menu.home" })}
          </LocalizedLink>
        </li>
        <li>
          <LocalizedLink
            to={generatePath(AppRoute.Tour, {
              categoryId: categoryId,
              tourId: tour.id,
            })}
          >
            {tour.name[locale]}
          </LocalizedLink>
        </li>
        { poi ?
          <li>{poi.name[locale]}</li>
          :
          null
        }
      </ul>
    </div>
  );
}
