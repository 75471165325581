import React from "react";
import { Breadcrumb } from "./Breadcrumb";
import { Carroussel } from "./Carroussel";
import { Doc } from "./Doc";
import { Title } from "./Title";
import { Transcription } from "./Transcription";
import { TourMap } from "../Map/TourMap";

export function TabsContent(props) {
  //props
  const tour = props.tour;
  const poi = props.poi;
  const categoryId = props.categoryId;
  const locale = props.locale;
  const formatMessage = props.formatMessage;
  const documentSelected = props.documentSelected;
  const setDocumentSelected = props.setDocumentSelected;

  const hasDocument = poi.documents.length !== 0;

  const documents = hasDocument
    ? poi.documents.map((document) => {
        return document.type === "image" ? document : "";
      })
    : "";

  const images = [poi.images];

  if (hasDocument) {
    documents.map((doc) => {
      images.push(doc);
      return null;
    });
  }

  return (
    <>
      <div id="tabs-content" className="row">
        <div id="tab-content-0" className="col-lg-6 col-12">
          <div className="hidden-md">
            <div id="top" className="row">
              <Breadcrumb
                tour={tour}
                locale={locale}
                formatMessage={formatMessage}
                poi={poi}
                categoryId={categoryId}
              />
              <Title
                tour={tour}
                poi={poi}
                locale={locale}
                className="hidden-sm"
              />
            </div>
          </div>
          <Transcription poi={poi} locale={locale} />
        </div>
        <div id="tab-content-1" className="col-lg-6 col-12">
          <div className="hidden-md">
            <Carroussel className="carroussel-tab" id="poiCarroussel" images={images} interval={null} locale={locale}/>
            <div id="map">
              <TourMap
                tour={tour}
                poi={poi}
                locale={locale}
                categoryId={categoryId}
              />
            </div>
          </div>
          <div className="hidden-lg">
            <Doc
              poi={poi}
              locale={locale}
              documentSelected={documentSelected}
              setDocumentSelected={setDocumentSelected}
            />
          </div>
        </div>
      </div>
    </>
  );
}
